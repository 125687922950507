export default function tabs() {
	const attr = "[js-tab]";
	const tabs = document.querySelectorAll(`.tabs${attr}`);

	if (!tabs.length) return;

	tabs.forEach((tab) => initTab(tab));

	function initTab(tab) {
		const collapses = tab.querySelectorAll(".tab-collapse");
		const tabItems = tab.querySelectorAll(".tabs-wrapper .tab-item");

		let currentIndex = 0;
		let progressInterval;

		tabItems.forEach((item, index) => {
			item.addEventListener("click", () => {
				if (currentIndex !== index) {
					tabItems[currentIndex].classList.remove("active");
					collapses[currentIndex].classList.remove("active");

					currentIndex = index;

					tabItems[currentIndex].classList.add("active");
					collapses[currentIndex].classList.add("active");

					const progressBar = tabItems[currentIndex].querySelector(".progress-bar");
					progressBar.style.width = "0";

					clearInterval(progressInterval); 

					progressInterval = setInterval(() => {
						let progress = parseFloat(progressBar.style.width) || 0;
						progress += 0.5; 

						if (progress >= 100) {
							progress = 100;
							clearInterval(progressInterval);
							nextTab();
						}

						progressBar.style.width = `${progress}%`;
					}, 50); // Incremento a cada 50ms para suavizar a animação
				}
			});
		});

		function nextTab() {
			tabItems[currentIndex].classList.remove("active");
			collapses[currentIndex].classList.remove("active");

			currentIndex = (currentIndex + 1) % tabItems.length;

			tabItems[currentIndex].classList.add("active");
			collapses[currentIndex].classList.add("active");

			const progressBar = tabItems[currentIndex].querySelector(".progress-bar");
			progressBar.style.width = "0";

			clearInterval(progressInterval); 

			progressInterval = setInterval(() => {
				let progress = parseFloat(progressBar.style.width) || 0;
				progress += 0.5; 

				if (progress >= 100) {
					progress = 100;
					clearInterval(progressInterval);
					nextTab();
				}

				progressBar.style.width = `${progress}%`;
			}, 50); 
		}

		// Inicia a primeira guia
		const progressBar = tabItems[currentIndex].querySelector(".progress-bar");
		progressBar.style.width = "0";

		progressInterval = setInterval(() => {
			let progress = parseFloat(progressBar.style.width) || 0;
			progress += 0.5; 
			if (progress >= 100) {
				progress = 100;
				clearInterval(progressInterval);
				nextTab();
			}

			progressBar.style.width = `${progress}%`;
		}, 50); 
	}
}
