export default function SwiperEadFunciona(){
    // breakpoint where swiper will be destroyed
// and switches to a dual-column layout
const breakpoint = window.matchMedia( '(min-width:1024px)' );
// keep track of swiper instances to destroy later
let swiperCards;

const breakpointChecker = function() {
   // if larger viewport and multi-row layout needed
   if ( breakpoint.matches === true ) {
      // clean up old instances and inline styles when available
      if ( swiperCards !== undefined ) swiperCards.destroy( true, true );
      // or/and do nothing
      return;
   // else if a small viewport and single column layout needed
   } else if ( breakpoint.matches === false ) {
      // fire small viewport version of swiper
      return enableSwiper();
   }
};

const enableSwiper = function() {
   swiperCards = new Swiper ('.swiper-cards', {
      slidesPerView: 1,
      
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
    }

   });
};

breakpoint.addListener(breakpointChecker);
// kickstart
breakpointChecker();
   
}