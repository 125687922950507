// PAGES
const pageHome = document.querySelector(".page_home");
const pageEad = document.querySelector(".body_ead");
const pageNeat = document.querySelector(".body_neat");
const pageSubscriptions = document.querySelector(".body_subscriptions");

// SCRIPT SENHA LOGIN
import mostrarSenha from "./mostrarSenha.js"
import showFormErrors from "./showFormErrors.js";
import menu from "./menu.js";
import tabs from "./tabs.js";
import maskaSwipersHome from "./swipers.js";
import maskaEadSwipers from "./page/maska_ead/swiperEad.js"
import maskaNeatSwipers from "./page/maska_neat/swiperNeat.js";
import maskaSubscriptionsSwipers from "./page/maska_subscriptions/swiperSubscriptions.js";
import scriptsHome from "./page/maska_home/scriptsHome.js";
import SwiperEadFunciona from "./page/maska_ead/swiperEadFunciona.js";
import SwiperNeatFunciona from "./page/maska_neat/swiperNeatFunciona.js";
import SwiperSubscriptionsFunciona from "./page/maska_subscriptions/swiperSubscriptionsFunciona.js";
import animarTexto from "./gsap.js";
import { animaAoScroll } from "./animations/gsapanimations.js";
import maskaContato from "./maskaContato.js";

gsap.registerPlugin(ScrollTrigger);
menu()
mostrarSenha()
showFormErrors()
maskaSwipersHome()
animaAoScroll();
maskaContato();

// ★ Pages Scopo
if (pageHome) {
  scriptsHome();
  tabs();
  
} else if (pageEad) {
  maskaEadSwipers();
  SwiperEadFunciona();
} else if (pageNeat) {
  maskaNeatSwipers();
  SwiperNeatFunciona();
} else if (pageSubscriptions) {
  maskaSubscriptionsSwipers();
  SwiperSubscriptionsFunciona();
}

document.addEventListener("DOMContentLoaded", () =>{
  document.body.classList.add("dcl")
  if ( !window.matchMedia('(max-width: 768px)').matches ){
    animarTexto();
  }
}
);
