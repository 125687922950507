export function animaAoScroll() {

    function floatingElements() {
        const elements = document.querySelectorAll('.floating-element');

        elements.forEach((el, index) => {
            setTimeout(() => {
                const animation = gsap.to(el, {
                    y: 5,
                    duration: 1,
                    yoyo: true,
                    repeat: -1,
                    ease: 'none',
                });
            }, 100 * index);
        });
    }



    function animateFrom(elem, direction, isMobile) {

        direction = direction | .5;
        let delay = 0
        let ease = elem.dataset?.ease || "sine.out"
        var x = 0,
            y = 0
        //direção
        if (elem.classList.contains("animate-left")) {
            x = -30;
            y = 0;
        } else if (elem.classList.contains("animate-right")) {
            x = 30;
            y = 0;

        } else if (elem.classList.contains("animate-down")) {
            y = 50;

        }
        if (isMobile) {
            y = 30
            x = 0
        }


        //delay
        if (elem.dataset.delay) {
            delay = `.${elem.dataset.delay}`

        }
        /* animaçao */
        return gsap
            .timeline()
            .fromTo(elem, {
                x: x,
                y: y,
                autoAlpha: 0,
            }, {
                duration: 1,
                x: 0,
                y: 0,
                autoAlpha: 1,
                delay: delay,
                ease: ease,
                onComplete: () => elem.classList.add("animou"),
                // overwrite: "auto"
            });
    }

    function hide(elem) {
        gsap.set(elem, {
            autoAlpha: 0
        });
    }
    function unhide(elem) {
        gsap.set(elem, { autoAlpha: 1 });
    }

    document.addEventListener("DOMContentLoaded", function () {

        var wideScreen = window.matchMedia("(min-width: 800px)");
        var narrowScreen = window.matchMedia("(max-width: 799px)");

        gsap.utils.toArray(".js-animate").forEach(function (elem) {
            hide(elem);
            //     if(wideScreen.matches){
            //         hide(elem); // assure that the element is hidden when scrolled into view
            //     }else {
            //         unhide(elem); // assures that the element is visible when scrolled into view below 800px screen-width
            //   }

            ScrollTrigger.matchMedia({

                "(min-width: 800px)": function () {

                    ScrollTrigger.create({
                        trigger: elem,
                        once: true,
                        start: "top+=50px bottom",
                        // markers: true,
                        onEnter: function () {
                            gsap.timeline()
                                // .timeScale(0.5)
                                .add(animateFrom(elem))
                                .play()

                        },


                        // onEnterBack: function () {
                        //     animateFrom(elem, -1)
                        // },
                        // onLeave: function () {
                        //     hide(elem)
                        // } // assure that the element is hidden when scrolled into view
                    });
                },
                "(max-width: 799px)": function () {
                    // The ScrollTriggers created inside these functions are segregated and get
                    // reverted/killed when the media query doesn't match anymore. 

                    ScrollTrigger.saveStyles(".animate-left, .animate-right, .animate-down");
                    ScrollTrigger.create({
                        trigger: elem,
                        once: true,
                        start: "top+=50px bottom",
                        // markers: true,
                        onEnter: function () {
                            animateFrom(elem, 1, 'is-mobile')
                        },


                        // onEnterBack: function () {
                        //     animateFrom(elem, -1)
                        // },
                        // onLeave: function () {
                        //     hide(elem)
                        // } // assure that the element is hidden when scrolled into view
                    });

                },

            })

        });



        floatingElements();
        // footerAnim();

    });


}
