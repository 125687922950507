export default function animarTexto() {
    function animateTitle(elem) {
      const textContent = elem.innerHTML;
      elem.innerHTML = '';
  
      let delayInfo = 0;
      if (elem.dataset.delay) {
        delayInfo = +elem.dataset.delay;
      }
  
      gsap.set(elem, {
        autoAlpha: 1,
      });
  
      const paragraphs = textContent.split('<br>');
      paragraphs.forEach((paragraph, pIndex) => {
        const paragraphElement = document.createElement('p');
        elem.appendChild(paragraphElement);
  
        paragraphElement.innerHTML = paragraph;
  
        const textChars = paragraphElement.textContent.split('');
        paragraphElement.textContent = '';
  
        textChars.forEach(function (char, index) {
          const charSpan = document.createElement('span');
          charSpan.textContent = char;
          if (charSpan.textContent === ' ') {
            charSpan.classList.add('space-span');
          }
          paragraphElement.appendChild(charSpan);
  
          gsap.fromTo(
            charSpan,
            {
              opacity: 0,
              y: 2,
            },
            {
              opacity: 1,
              y: 0,
              duration: 0.1,
              ease: 'power1.out',
              delay: (index * 0.03) + (pIndex * 0.2) + delayInfo,
            }
          );
        });
      });
    }
  
    gsap.utils.toArray('.title-reveal').forEach(function (elem) {
      gsap.set(elem, {
        autoAlpha: 0,
      });
      ScrollTrigger.create({
        trigger: elem,
        once: true,
        start: 'top+=50px bottom',
        // markers: true,
        onEnter: function () {
          animateTitle(elem);
        },
      });
    });
  }
  