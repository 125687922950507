export default function maskaSwipersHome() {
    const swiperParceiros1 = new Swiper(".swiper-parceiros-linha1", {
        slidesPerView: 1.5,
        spaceBetween: 16,
        centeredSlides: true,
        centeredSlidesBounds: true,
        loop: true,
        speed: 10000,
        autoplay: {
            delay: 0,
            disableOnInteraction: false,
        },
        // pagination: {
        //   el: "#sobre .swiper-pagination",
        //   clickable: true,
        // },
        breakpoints: {
            640: {
                slidesPerView: 2.2,
                spaceBetween: 32,
            },
            768: {
                slidesPerView: 3.2,
                spaceBetween: 32,
            },
            1024: {
                slidesPerView: 4,
                spaceBetween: 32,
            },
        },
    });

    const swiperParceiros2 = new Swiper(".swiper-parceiros-linha2", {
        slidesPerView: 1.5,
        spaceBetween: 16,
        loop: true,
        speed: 10000,
        autoplay: {
            delay: 0,
            disableOnInteraction: false,
            reverseDirection: true,
        },
        // pagination: {
        //   el: "#sobre .swiper-pagination",
        //   clickable: true,
        // },
        breakpoints: {
            640: {
                slidesPerView: 2.2,
                spaceBetween: 32,
            },
            768: {
                slidesPerView: 3.2,
                spaceBetween: 32,
            },
            1024: {
                slidesPerView: 4,
                spaceBetween: 32,
            },
        },
    });
}