export default function maskaContato() {
    const contatoFormSubmit = document.querySelector('#form-submit')
    contatoFormSubmit && contatoFormSubmit.addEventListener('submit', function (event) {
        event.preventDefault();
        disabledBtn()
        const form_data = {
            empresa: document.querySelector('#empresa').value,
            produto: document.querySelector('#produto').value,
            nome: document.querySelector('#nome').value,
            telefone: document.querySelector('#telefone').value,
            email: document.querySelector('#email_input').value,
            whatsapp: document.querySelector('#whatsapp_input').value,
        }
        const url = "/fale-conosco/"
        axios.post(url, form_data)
            .then((response) => showMessage(response))
            .catch((response) => showMessage(response));
    });

    function showMessage(response) {
        const message = response.data ? response.data.message : response.message
        GLOBAL.showToastify(message)
        contatoFormSubmit.reset()
        setTimeout(enabledBtn, 1000)
    }

    const btnSubmit = document.querySelector(".form-send-mensage .btn-contato")

    function disabledBtn() {
        btnSubmit.disabled = true
        btnSubmit.style.opacity = "0.5"
    }

    function enabledBtn() {
        btnSubmit.disabled = false
        btnSubmit.style.opacity = "1.0"
    }
}