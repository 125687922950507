export default function scriptsHome() {

   const breakpoint = window.matchMedia('(min-width:750px)');

   let swiperCards;

   const breakpointChecker = function () {

      if (breakpoint.matches === true) {

         if (swiperCards !== undefined) swiperCards.destroy(true, true);

         return;

      } else if (breakpoint.matches === false) {

         return enableSwiper();
      }
   };

   const enableSwiper = function () {
      swiperCards = new Swiper('.swiper-cards', {
         slidesPerView: 1,

         pagination: {
            el: ".swiper-pagination",
            clickable: true,
         }

      });
   };

   breakpoint.addListener(breakpointChecker);

   breakpointChecker();

}