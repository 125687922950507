export default function maskaEadSwipers() {
    const swiperProjects1 = new Swiper(".swiper-projects-ead-linha1", {
        slidesPerView: 1.5,
        spaceBetween: 16,
        centeredSlides: true,
        centeredSlidesBounds: true,
        loop: true,
        speed: 10000,
        autoplay: {
            delay: 0,
            disableOnInteraction: false,
        },
        breakpoints: {
            640: {
                slidesPerView: 2.2,
                spaceBetween: 32,
            },
            768: {
                slidesPerView: 3.2,
                spaceBetween: 32,
            },
            1024: {
                slidesPerView: 4,
                spaceBetween: 32,
            },
        },
    });

    const swiperProjects2 = new Swiper(".swiper-projects-ead-linha2", {
        slidesPerView: 1.5,
        spaceBetween: 16,
        loop: true,
        speed: 10000,
        autoplay: {
            delay: 0,
            disableOnInteraction: false,
            reverseDirection: true,
        },
        breakpoints: {
            640: {
                slidesPerView: 2.2,
                spaceBetween: 32,
            },
            768: {
                slidesPerView: 3.2,
                spaceBetween: 32,
            },
            1024: {
                slidesPerView: 4,
                spaceBetween: 32,
            },
        },
    });
}